import { PropTypes } from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IconCheck, NamedLink, SecondaryButton } from '../../components';
import css from './Subscription.module.css';
import classNames from 'classnames';

export const SubscriptionComponent = props => {
  const {
    onSaveSubscription,
    onManageSubscription,
    subscription,
    createStripeSubscriptionInProgress,
    createStripeSubscriptionError,
    showListingErrorMessage,
    intl,
    hideLinks,
  } = props;

  const language = intl.locale;

  const plans = [
    {
      name: 'starter',
      header: intl.formatMessage({ id: 'Subscription.starter.title' }),
      description: intl.formatMessage({ id: 'Subscription.starter.description' }),
      duration: intl.formatMessage({ id: 'Subscription.starter.duration' }),
      price: intl.formatMessage({ id: 'Subscription.starter.price' }),
      benefits: [intl.formatMessage({ id: 'Subscription.starter.benefits.1' })],
      ctaContent: intl.formatMessage({ id: 'Subscription.select' }),
      selectedCtaContent: intl.formatMessage({ id: 'Subscription.changeOrCancel' }),
    },
    {
      name: 'basic',
      header: intl.formatMessage({ id: 'Subscription.basic.title' }),
      description: intl.formatMessage({ id: 'Subscription.basic.description' }),
      duration: intl.formatMessage({ id: 'Subscription.basic.duration' }),
      price: intl.formatMessage({ id: 'Subscription.basic.price' }),
      benefits: [intl.formatMessage({ id: 'Subscription.basic.benefits.1' })],
      ctaContent: intl.formatMessage({ id: 'Subscription.select' }),
      selectedCtaContent: intl.formatMessage({ id: 'Subscription.changeOrCancel' }),
    },
    {
      name: 'pro',
      header: intl.formatMessage({ id: 'Subscription.pro.title' }),
      description: intl.formatMessage({ id: 'Subscription.pro.description' }),
      duration: intl.formatMessage({ id: 'Subscription.pro.duration' }),
      price: intl.formatMessage({ id: 'Subscription.pro.price' }),
      benefits: [intl.formatMessage({ id: 'Subscription.pro.benefits.1' })],
      ctaContent: intl.formatMessage({ id: 'Subscription.select' }),
      selectedCtaContent: intl.formatMessage({ id: 'Subscription.changeOrCancel' }),
    },
    {
      name: 'premium',
      header: intl.formatMessage({ id: 'Subscription.premium.title' }),
      description: intl.formatMessage({ id: 'Subscription.premium.description' }),
      duration: intl.formatMessage({ id: 'Subscription.premium.duration' }),
      price: intl.formatMessage({ id: 'Subscription.premium.price' }),
      benefits: [intl.formatMessage({ id: 'Subscription.premium.benefits.1' })],
      ctaContent: intl.formatMessage({ id: 'Subscription.select' }),
      selectedCtaContent: intl.formatMessage({ id: 'Subscription.changeOrCancel' }),
    },
    {
      name: 'enterprise',
      header: intl.formatMessage({ id: 'Subscription.enterprise.title' }),
      description: intl.formatMessage({ id: 'Subscription.enterprise.description' }),
      duration: intl.formatMessage({ id: 'Subscription.enterprise.duration' }),
      price: intl.formatMessage({ id: 'Subscription.enterprise.price' }),
      benefits: [intl.formatMessage({ id: 'Subscription.enterprise.benefits.1' })],
      ctaContent: intl.formatMessage({ id: 'Subscription.select' }),
      selectedCtaContent: intl.formatMessage({ id: 'Subscription.changeOrCancel' }),
    },
  ];

  return (
    <section className={css.subscriptionSection}>
      {!hideLinks && (
        <>
          <p>{intl.formatMessage({ id: 'Subscription.descriptionLine1' })}</p>
          <p>
            {intl.formatMessage(
              { id: 'Subscription.manageSubscriptions' },
              {
                here: (
                  <a onClick={() => onManageSubscription({ language })}>
                    {intl.formatMessage({ id: 'Subscription.manageSubscriptionsLink' })}
                  </a>
                ),
              }
            )}
          </p>
        </>
      )}

      <div className={css.subscriptions}>
        {plans.map((plan, i) => (
          <div
            key={i}
            className={classNames(css.plan, {
              [css.currentPlan]: subscription === plan.name,
            })}
            data-subscription={plan.name}
          >
            <div className={css.header}>
              <div className={css.duration}>{plan.duration}</div>
              <div className={css.heading}>
                <span>
                  {subscription === plan.name && (
                    <div className={css.currentPlanCheck}>
                      <IconCheck />
                    </div>
                  )}
                </span>
                <span>{plan.header}</span>
              </div>
            </div>
            <div className={css.price}>
              <div className={css.priceWrapper}>
                <span>{plan.price}</span>
              </div>
              <div className={css.durationWrapper}>
                <div className={css.perMonth}>
                  {intl.formatMessage({ id: 'Subscription.duration' })}
                </div>
              </div>
            </div>
            {subscription === plan.name && (
              <div className={css.currentPlanText}>
                {intl.formatMessage({ id: 'Subscription.currentPlan' })}
              </div>
            )}
            <hr />
            <div className={css.details}>
              {plan.benefits.map((benefit, j) => (
                <div key={j}>{benefit}</div>
              ))}
            </div>

            <footer>
              <div className={css.note}>{plan.description}</div>

              {!!onSaveSubscription && (
                <div className={css.ctaContainer}>
                  <SecondaryButton
                    inProgress={createStripeSubscriptionInProgress === plan.name}
                    className={css.ctaButton}
                    onClick={() => onSaveSubscription({ subscription: plan.name, language })}
                  >
                    {subscription === plan.name ? plan.selectedCtaContent : plan.ctaContent}
                  </SecondaryButton>
                </div>
              )}
            </footer>
          </div>
        ))}
      </div>
      {createStripeSubscriptionError && (
        <div className={css.error}>
          {intl.formatMessage({ id: 'Subscription.subscriptionError' })}
        </div>
      )}
    </section>
  );
};

SubscriptionComponent.defaultProps = {
  showListingErrorMessage: true,
};

SubscriptionComponent.propTypes = {
  showListingErrorMessage: PropTypes.bool,
  onSaveSubscription: PropTypes.func,
};

export default SubscriptionComponent;
