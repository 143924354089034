import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
// import moment from 'moment';
// import Countdown from 'react-countdown';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import {
  isFieldForCategory,
  pickCategoryFields,
  pickCustomFieldProps,
  isFieldForListingType,
} from '../../util/fieldHelpers.js';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCardList.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const { className, rootClassName, intl, listing, renderSizes, setActiveListing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData = {} } = currentListing.attributes;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const { endDate, categoryLevel1 } = publicData || {};

  const listingFieldConfigs = config.listing.listingFields;
  const categoryConfiguration = config.categoryConfiguration;
  const { key: categoryPrefix, categories: listingCategoriesConfig } = categoryConfiguration;
  const categoriesObj = pickCategoryFields(publicData, categoryPrefix, 1, listingCategoriesConfig);
  const currentCategories = Object.values(categoriesObj);

  const isFieldForSelectedCategories = fieldConfig => {
    const isTargetCategory = isFieldForCategory(currentCategories, fieldConfig);
    return isTargetCategory;
  };
  const propsForCustomFields =
    pickCustomFieldProps(
      publicData,
      {},
      listingFieldConfigs,
      'listingType',
      isFieldForSelectedCategories
    ) || [];

  const metadata = {};
  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const listingType = publicData.listingType;
    const isTargetListingType = isFieldForListingType(listingType, config);
    const isTargetCategory = isFieldForCategory(config);

    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = typeof publicDataValue != null ? publicDataValue : metadataValue;

    if (isDetail && isTargetListingType && isTargetCategory && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };
  // const existingListingFields = listingFieldConfigs.reduce(pickListingFields, []);
  const categoryProps = categoryConfiguration.categories.find(c => c.id === categoryLevel1);
  const adSlotsProps = propsForCustomFields.find(f => f.key === 'adSlots');
  const adReachProps = propsForCustomFields.find(f => f.key === 'adReach');
  const adDurationProps = propsForCustomFields.find(f => f.key === 'adDuration');
  const targetAudienceProps = propsForCustomFields.find(f => f.key === 'targetAudience');
  // const hasEndDate = !!endDate;
  // const endDateMoment = hasEndDate ? moment(endDate) : null;
  // const currentDate = moment();
  // const timeRemainingToCloseListing = hasEndDate ? endDateMoment.diff(currentDate) : null;
  // const listingEndsMessage = intl.formatMessage({ id: 'OrderPanel.endsIn' });

  // const renderer = ({ days, hours, minutes, seconds, completed }) => {
  //   if (completed) {
  //     return (
  //       <span className={css.countdown}>
  //         {intl.formatMessage({ id: 'OrderPanel.listingEnded' })}
  //       </span>
  //     );
  //   } else if (days) {
  //     return (
  //       <span
  //         className={css.countdown}
  //       >{`${listingEndsMessage} ${days}d ${hours}h ${minutes}m ${seconds}s`}</span>
  //     );
  //   } else if (hours) {
  //     return (
  //       <span
  //         className={css.countdown}
  //       >{`${listingEndsMessage} ${hours}h ${minutes}m ${seconds}s`}</span>
  //     );
  //   } else {
  //     return (
  //       <span className={css.countdown}>{`${listingEndsMessage} ${minutes}m ${seconds}s`}</span>
  //     );
  //   }
  // };

  const colorClass =
    categoryLevel1 === 'google-ads1'
      ? css.google
      : categoryLevel1 === 'meta-ads1'
      ? css.meta
      : categoryLevel1 === 'amazon-ads1'
      ? css.amazon
      : categoryLevel1 === 'tiktok-ads1'
      ? css.tiktok
      : categoryLevel1 === 'direct-mail1'
      ? css.directEmail
      : categoryLevel1 === 'collab-news1'
      ? css.emailNewsletter
      : null;

  return (
    <NamedLink className={classNames(classes, colorClass)} name="ListingPage" params={{ id, slug }}>
      <div className={css.infoContainer}>
        <AspectRatioWrapper
          className={css.aspectRatioWrapper}
          width={aspectWidth}
          height={aspectHeight}
          {...setActivePropsMaybe}
        >
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={variants}
            sizes={renderSizes}
          />
        </AspectRatioWrapper>
        <div className={css.info}>
          <div className={css.titleWrapper}>
            <div className={css.title}>
              {richText(title, {
                longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                longWordClass: css.longWord,
              })}
            </div>
            {price ? (
              <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
            ) : null}
          </div>
          <div className={css.secondaryInfo}>
            <div className={css.secondaryInfo1}>
              {!!categoryProps?.name ? (
                <div className={css.category}>{categoryProps?.name}</div>
              ) : null}
              {!!adSlotsProps ? (
                <div className={css.adSlots}>
                  <span className={css.heading}>{adSlotsProps?.heading}: </span>
                  <span className={css.value}>{adSlotsProps?.text}</span>
                </div>
              ) : null}
            </div>
            <div className={css.secondaryInfo2}>
              {!!adReachProps ? (
                <div className={css.adReach}>
                  <span className={css.heading}>{adReachProps?.heading}: </span>
                  <span className={css.value}>{adReachProps?.text}</span>
                </div>
              ) : null}
              {!!adDurationProps ? (
                <div className={css.adDuration}>
                  <span className={css.heading}>{adDurationProps?.heading}: </span>
                  <span className={css.value}>{adDurationProps?.text}</span>
                </div>
              ) : null}
              {!!targetAudienceProps ? (
                <div className={css.targetAudience}>
                  <span className={css.heading}>{targetAudienceProps?.heading}: </span>
                  <span className={css.value}>{targetAudienceProps?.text}</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className={css.buttonContainer}>
        <div className={css.timeRemainingToCloseListing}>
          {/* {hasEndDate ? (
            <Countdown date={Date.now() + timeRemainingToCloseListing} renderer={renderer} />
          ) : (
            <span>{intl.formatMessage({ id: 'OrderPanel.listingEnded' })}</span>
          )} */}
        </div>
        <button className={css.button}>{intl.formatMessage({ id: 'SearchPage.join' })}</button>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
